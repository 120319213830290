<template>
  <svg width="160" height="68" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="2" fill="none" fill-rule="evenodd">
      <path
        d="M1 61.776c27.24-1.792 43.955-7.659 50.142-17.603 9.28-14.915-11.185-26.19-25.071-18.72-13.887 7.469 4.04 40.874 34.66 40.874 20.412 0 40.065-8.36 58.958-25.081"
        stroke="#B4C1D6"
        stroke-dasharray="2,2"
      />
      <g stroke="#5E239E">
        <path d="m108.081 27.417 17.252 4.283 3.29 11.95-1.137-11.15 17.114 3.8 13.195-34.2z" />
        <path d="m125.433 31.62 32.301-29.5-30.253 30.28m1.258 11.493 7.151-9.558" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PaperPlane',
}
</script>

<style lang="scss" scoped></style>
