<template>
  <v-card class="mx-auto py-5" elevation="5" width="100%" max-width="38rem" rounded="xl">
    <v-card width="100%" max-width="30rem" class="mt-2 mx-auto" elevation="0">
      <v-card-text class="pb-0">
        <v-row align="center" justify="center" class="pb-5">
          <v-col cols="12">
            <PaperPlane />
          </v-col>

          <v-col cols="12">
            <div
              class="headline black--text text-left pa-0 pt-4 mb-0"
              v-html="
                $t('login.confirm_your_email_text', {
                  email: $route.params.email,
                })
              "
            ></div>
          </v-col>

          <v-col cols="12">
            <p class="text-subtitle-1 text-left pa-0 mb-0">
              {{ $t('login.check_spam_advice') }}
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="start" class="bottom-message pt-4 mt-10" no-gutters>
          <v-col cols="12" class="text-center">
            <router-link class="font-weight-regular" :to="{ name: 'register' }">
              {{ $t('login.send_again') }}
            </router-link>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import PaperPlane from '@access/components/icons/PaperPlane'

export default {
  name: 'RegistrationDone',
  components: {
    PaperPlane,
  },
}
</script>

<style lang="scss" scoped>
.bottom-message {
  border-top: 1px solid #e2e2e2;
}
</style>
